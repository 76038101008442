import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoIconGenerator from '../../../common/icon/FanGoIconGenerator';
import RingComp from '../../../common/RingComp';
import FanGoTitle from '../../../common/texts/FanGoTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    christmasAnalysedContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    textIconContainer: {
      display: 'flex',
      rowGap: 10,
      flexDirection: 'column',
    },
    analysisContainer: {
      display: 'flex',
      alignItems: 'center',
      rowGap: 20,
    },
    imgContainer: {
      borderRadius: 10,
    },
    titleContainer: {
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  })
);

const ChristmasAnalysis = ({ title, subTitle, image, christmasStatistics }) => {
  const classes = useStyles();
  const { asset, altText } = image;
  const { gatsbyImageData } = asset;
  const formattedChristmasStats = christmasStatistics.map((statObj) => {
    const { textId, text } = statObj;
    const icon = FanGoIconGenerator({
      iconName: textId,
      height: '30px',
    });

    return { textId, text, icon };
  });

  return (
    <>
      <FanGoTitle title={title} marginBottom={1} />
      <Grid container columnSpacing={5} className={classes.analysisContainer}>
        <Grid
          item
          sm={9}
          md={6}
          className={classes.imgContainer}
          sx={{ margin: 'auto' }}
        >
          <div>
            <GatsbyImage
              image={gatsbyImageData}
              alt={altText || ''}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 10,
              }}
            />
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <div className={classes.titleContainer}>
            <FanGoTitle title={subTitle} variant="h3" marginBottom={2} />
          </div>
          <div className={classes.textIconContainer}>
            {formattedChristmasStats.map((statObj, index) => {
              const { textId, text, icon } = statObj;
              return (
                <div
                  key={textId}
                  className={classes.christmasAnalysedContainer}
                >
                  <div style={{ paddingRight: 10 }}>
                    <RingComp
                      size={50}
                      content={icon}
                      background="linear-gradient(45deg, #47b19a 10%, #141855 90%)"
                    />
                  </div>
                  <Typography>{text}</Typography>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ChristmasAnalysis;
