import React from 'react';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import { Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import styles from '../../../common/styling/styles';
import FanGoTitle from '../../../common/texts/FanGoTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    featureCardContainer: {
      padding: theme.spacing(1.5),
    },
    featureCard: {
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.3)',
      borderRadius: 8,
      height: '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      textAlign: 'center',
      color: 'white',
    },
    featureCardTextContainer: {
      borderRadius: 8,
    },
    christmasFeaturesOptionsContainer: {
      [theme.breakpoints.down('sm')]: {
        rowGap: 10,
      },
    },
    christmasFeaturesContainer: {
      [theme.breakpoints.down('sm')]: {
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius: 8,
        maxWidth: '80%',
        margin: 'auto',
        background: `linear-gradient(10deg, #47b19a 30%, #141855 90%)`,
      },
    },
  })
);

const ChristmasFeatures = ({ keyChristmasFeatures }) => {
  const classes = useStyles();

  const getRandomNumber = ({ min, max }) => {
    const randNum = Math.random();
    return randNum * (max - min) + min;
  };
  const randBackground = () => {
    const randDeg = getRandomNumber({ min: 10, max: 45 });
    const firstColourPercentStop = getRandomNumber({ min: 1, max: 50 });
    const secondColourPercentStop = getRandomNumber({ min: 70, max: 100 });
    return `linear-gradient(${randDeg}deg, #47b19a ${firstColourPercentStop}%, #141855 ${secondColourPercentStop}%)`;
  };

  return (
    <div>
      <FanGoTitle
        title="Key Christmas Features"
        variant="h3"
        marginBottom={2}
      />
      <div className={classes.christmasFeaturesContainer}>
        <Grid container className={classes.christmasFeaturesOptionsContainer}>
          {keyChristmasFeatures.map((featureObj, index) => {
            const { textId, text } = featureObj;
            return (
              <React.Fragment key={textId}>
                <Grid
                  item
                  sm={3}
                  sx={{ display: { xs: 'none', sm: 'block' } }}
                  key={`${textId}-largeScreenGridItem`}
                  className={classes.featureCardContainer}
                >
                  <div
                    className={classes.featureCard}
                    style={{
                      backgroundColor: '#141855',
                    }}
                  >
                    <Typography variant="h5">{text}</Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  key={`${textId}-smallScreenGridItem`}
                  sx={{
                    display: { xs: 'block', sm: 'none' },
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      color: 'white',
                      columnGap: 8,
                      width: '80%',
                      margin: 'auto',
                    }}
                  >
                    <AcUnitIcon />
                    <Typography variant="h4" style={{ color: 'white' }}>
                      {text}
                    </Typography>
                  </div>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default ChristmasFeatures;
