import React from 'react';

import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoBody from '../../common/body/FanGoBody';
import GreenFanGoLinkButton from '../../common/button/GreenFanGoLinkButton';
import NavBarButton from '../../common/button/NavBarButton';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText } from '../../common/texts';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const BespokeSoftwareSection = ({ pricingTextBespokeSoftware }) => {
  const { title, _rawBody } = pricingTextBespokeSoftware;

  return (
    <FanGoBody>
      <MainText textVariant="h2" value={title} fontColor={styles.darkBlue} />
      <FanGoTextProcessor textAlign="center" text={_rawBody} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          marginTop: 25,
        }}
      >
        <NavBarButton
          buttonName="Contact Us"
          onClick={undefined}
          hrefKey="contactus"
          paddingTopBottom="12px"
          fontSize="15px"
          maxWidth="150px"
        />
      </div>
    </FanGoBody>
  );
};

export default BespokeSoftwareSection;
