import React from 'react';

import { Typography, useMediaQuery } from '@mui/material';

import FanGoBody from '../../common/body/FanGoBody';
import RingComp from '../../common/RingComp';
import styles from '../../common/styling/styles';
import { MainText } from '../../common/texts';
import FanGoTitle from '../../common/texts/FanGoTitle';

const LaunchProcess = ({ launchProcessSteps }) => {
  const { title, launchSteps } = launchProcessSteps;
  const isMobile = useMediaQuery(`(max-width: 600px)`);
  return (
    <FanGoBody alternate>
      <FanGoTitle title={title} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 10,
          marginTop: 10,
        }}
      >
        {launchSteps.map((step, index) => {
          const { title, text } = step;
          const ringValue = (
            <Typography fontWeight="bold" variant="h3">
              {index + 1}
            </Typography>
          );
          return (
            <div key={title} style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ paddingRight: 15 }}>
                <RingComp
                  content={ringValue}
                  size={isMobile ? 50 : 80}
                  background="linear-gradient(45deg, #47b19a 30%, #057058 90%)"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h4">{title}</Typography>
                <Typography>{text}</Typography>
              </div>
            </div>
          );
        })}
      </div>
    </FanGoBody>
  );
};

export default LaunchProcess;
