import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React from 'react';

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoBody from '../../../common/body/FanGoBody';
import ChristmasAnalysis from './ChristmasAnalysis';
import ChristmasFeatures from './ChristmasFeatures';
import PricingSection from './PricingSection';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const ChristmasPackageSection = ({ christmasEventsPricingSteps }) => {
  const classes = useStyles();
  const {
    title,
    subtitle,
    christmasStatistics,
    keyChristmasFeatures,
    christmasEventsPackageImg,
    pricingBackgroundImg,
  } = christmasEventsPricingSteps;

  const { asset, altText } = pricingBackgroundImg;
  const { gatsbyImageData } = asset;
  const image = getImage(gatsbyImageData);
  const bgImage = convertToBgImage(image);
  return (
    <>
      <FanGoBody display="flex" rowGap={40} flexDirection="column">
        <ChristmasAnalysis
          title={title}
          subTitle={subtitle}
          image={christmasEventsPackageImg}
          christmasStatistics={christmasStatistics}
        />
        <ChristmasFeatures keyChristmasFeatures={keyChristmasFeatures} />
      </FanGoBody>
      <div
        style={{
          paddingTop: 50,
          paddingBottom: 50,
          background: `linear-gradient(10deg, #2d348a 30%, #141855 90%)`,
        }}
      >
        <PricingSection />
      </div>

      {/* <BackgroundImage
        style={{
          maxHeight: '800px',
          minHeight: '500px',
          width: '100%',
          backgroundColor: 'white',
          height: '100%',
          backgroundSize: 'contain',
          backgroundRepeat: 'repeat',
          backgroundPositionX: '10%',
          boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        }}
        {...bgImage}
      >
        {/* <div
          style={{
            height: '500px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <PricingSection />
        </div> */}
      {/* </BackgroundImage> */}
    </>
  );
};

export default ChristmasPackageSection;
