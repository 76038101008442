import { graphql } from 'gatsby';
import React, { useContext } from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import Separator from '../components/common/styling/Separator';
import styles from '../components/common/styling/styles';
import { TrustedBrandIcons } from '../components/PageComps/CommonComps';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import FAQs from '../components/PageComps/FAQsPageComps/FAQs';
import BespokeSoftwareSection from '../components/PageComps/PricingPageComps/BespokeSoftwareSection';
import ChristmasPackageSection from '../components/PageComps/PricingPageComps/christmasPricingComponents/ChristmasPackageSection';
import LaunchProcess from '../components/PageComps/PricingPageComps/LaunchProcess';
import { DataContext } from '../providers/DataProvider';

const PricingPage = ({ data }) => {
  const { heroSectionBackgroundImage: imageData } = useContext(DataContext);
  const { sanityPricingPage } = data;
  const {
    pageHead,
    launchProcessSteps,
    pricingTextBespokeSoftware,
    christmasEventsPricingSteps,
  } = sanityPricingPage;
  const { title, subTitle } = pageHead;

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="5% 50%"
        imageData={imageData}
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={subTitle}
            subTitlePlainText={true}
            titleRowSpacing={undefined}
            titlePaddingBottom={40}
            largeSubTitleSize
          />
        }
      />

      <BespokeSoftwareSection
        pricingTextBespokeSoftware={pricingTextBespokeSoftware}
      />
      <LaunchProcess launchProcessSteps={launchProcessSteps} />
      <ChristmasPackageSection
        christmasEventsPricingSteps={christmasEventsPricingSteps}
      />

      {/* <TextBody data={sanityPricingPage.pricingText} />

      <PricingMenu
        data={{
          pricingOptions: sanityPricingPage.pricingOptions,
          features: sanityPricingPage.features,
        }}
      /> */}

      <FAQs
        data={data.sanityFaqsPage.faqs}
        title={data.sanityFaqsPage.pageHead.subTitle}
      />

      <Separator height={100} />

      <TrustedBrandIcons
        data={data.sanityCommonContent.trustedBy}
        paddingBottom={styles.allEight}
      />
    </>
  );
};

export default PricingPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityPricingPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityPricingPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        subTitle
      }
      pricingText: _rawPricingText
      pricingOptions {
        optionTitle
        optionId
        optionPrice
        priceInformation
        followUpText: _rawFollowUpText
      }
      launchProcessSteps {
        title
        launchSteps {
          title
          text
        }
      }
      pricingTextBespokeSoftware {
        title
        _rawBody
      }
      christmasEventsPricingSteps {
        title
        subtitle
        keyChristmasFeatures {
          textId
          text
        }
        christmasStatistics {
          textId
          text
        }
        christmasEventsPackageImg {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 900, formats: [WEBP])
          }
          altText
        }
        pricingBackgroundImg {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 900, formats: [WEBP])
          }
          altText
        }
      }
      features {
        title
        charity
        plus
        premium
      }
    }
    sanityFaqsPage(language: { eq: $language }) {
      pageHead {
        subTitle
      }
      faqs {
        title
        text
        category
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
    }
  }
`;
