import React from 'react';

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outterRing: {
      height: ({ size }) => size,
      width: ({ size }) => size,
      backgroundColor: ({ outterRingColor }) => outterRingColor,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: ({ background }) => background,
    },
    innerRing: {
      height: ({ size, borderThickness }) => size * borderThickness,
      width: ({ size, borderThickness }) => size * borderThickness,
      backgroundColor: ({ innerRingColor }) => innerRingColor,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
const RingComp: React.FC<{
  size: number;
  borderThickness?: number;
  content: any;
  outterRingColor?: string;
  innerRingColor?: string;
  background?: string;
}> = ({
  size = 100,
  outterRingColor = '#141855',
  innerRingColor = '#ffffff',
  content,
  background = null,
  borderThickness = 0.8,
}) => {
  const classes = useStyles({
    size,
    outterRingColor,
    innerRingColor,
    background,
    borderThickness,
  });
  return (
    <div className={classes.outterRing}>
      <div className={classes.innerRing}>{content}</div>
    </div>
  );
};

export default RingComp;
