import React from 'react';

import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoBody from '../../../common/body/FanGoBody';
import styles from '../../../common/styling/styles';
import FanGoTitle from '../../../common/texts/FanGoTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxShadow: '0 3px 10px rgb(255 255 255 / 0.4)',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: 8,
      maxWidth: '60%',
      margin: 'auto',
      textAlign: 'center',
      color: 'white',
      backgroundColor: '#141855',
    },
    feesContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    rowFlexContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    inlineText: {
      display: 'inline-block',
      margin: 0,
      padding: 0,
    },
    pricingBox: {
      marginTop: theme.spacing(1.5),
      [theme.breakpoints.down(500)]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  })
);

const ChristmasPricing = () => {
  const classes = useStyles();
  return (
    <>
      <FanGoTitle
        title="Christmas Events Package Pricing"
        marginBottom={3}
        variant="h3"
        color="#ffffff"
      />
      <div>
        <div className={classes.container}>
          <Typography variant="h5">No Development Fee Contract!</Typography>
          <div className={classes.feesContainer}>
            <div className={classes.rowFlexContainer}>
              <Typography>Monthly Fee (£) - 89</Typography>
              <p style={{ fontSize: '10px', margin: 0, padding: 0 }}>99</p>
            </div>
            <div className={classes.pricingBox}>
              <Typography>Choose Between</Typography>
              <p className={classes.inlineText}>4.5% Commission</p>
              <p className={classes.inlineText} style={{ fontWeight: 'bold' }}>
                &nbsp;OR&nbsp;
              </p>
              <p className={classes.inlineText}>£1.20 Per Ticket Sold</p>
            </div>
          </div>
          <Typography style={{ fontSize: 16 }}>
            These fees can either be deducted from the ticket or passed onto
            your guest
          </Typography>
          <Typography style={{ fontSize: 14 }}>
            *Prices Exclusive of VAT
          </Typography>
        </div>
      </div>
    </>
  );
};

export default ChristmasPricing;
